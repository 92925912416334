<script>
export default {
  name: 'Radio',
  components: {
    Icon: () => import('@/components/general/Icon'),
    RadioItem: () => import('@/components/general/RadioItem'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },
  props: {
    description: {
      type: String,
      default: null
    },
    value: null,
    validation: {
      type: Object,
      default: () => { return {} }
    },
    items: {
      type: Array,
      default: () => { return [] }
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    underDescription: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      mutableValue: this.value,
      mutableItems: this.items
    }
  },
  computed: {
    isRequired () {
      return this.validation.$params && typeof this.validation.$params.required === 'object'
    }
  },
  watch: {
    value (value) {
      this.mutableValue = value
    },
    disabled () {
      this.updateItems()
    }
  },
  created () {
    this.updateItems()
  },
  methods: {
    updateValue (value) {
      typeof this.validation.$touch === 'function' && this.validation.$touch()
      this.mutableValue = value
      this.$emit('input', this.mutableValue)
    },
    checkState (value) {
      return value !== undefined ? value === this.mutableValue : false
    },
    updateItems () {
      this.mutableItems = []
      this.items.forEach(obj => {
        this.mutableItems.push({
          ...obj,
          disabled: this.disabled
        })
        return obj
      })
    }
  }
}
</script>

<template>
  <div
    class="form-item form-item-radio"
    :class="{ 'has-error': validation.$error, 'form-item-horizontal': horizontal, 'theme-dark': dark, 'has-underdescription': underDescription }"
  >
    <p
      v-if="description"
      class="form-item-description"
    >
      {{ description }} <span v-if="!isRequired && !disabled">{{ $t('global:form.optional') }}</span>
    </p>
    <p
      v-if="underDescription"
      class="form-input-subtext"
    >
      {{ underDescription }}
    </p>
    <template v-for="(item, index) in mutableItems">
      <radio-item
        :key="index"
        :label="item.label"
        :disabled="item.disabled"
        :readonly="item.readonly"
        :checked="checkState(item.value)"
        :custom="custom"
        :value="item.value"
        :name="_uid"
        @change="updateValue"
      />
    </template>
    <div
      v-if="validation.$error"
      class="form-input-details"
    >
      <validation-message :validation="validation" />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>
<style src="@/assets/styles/themes/default/radio.css"></style>
