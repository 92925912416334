<script>
import { mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { formatSlug } from '@/support/utils/stringHelper'
import Radio from '../../../components/general/Radio'
export default {
  name: 'ManagementSurveyEdit',
  components: {
    Radio,
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    Checkbox: () => import('@/components/general/Checkbox'),
    Pagination: () => import('@/components/general/Pagination'),
    SelectField: () => import('@/components/general/SelectField'),
    RichTextEditor: () => import('@/components/general/RichTextEditor')
  },

  data () {
    return {
      pagination: {
        page: 1,
        lastPage: 2
      },

      backUrl: { name: 'management.survey' },
      formData: {
        id: null,
        name: '',
        type: 'satisfaction',
        description: '',
        finishText: '',
        fixedQuestions: true,
        visibilityEnabled: true,
        slug: ''
      },

      surveyTypes: [
        {
          value: 'satisfaction',
          text: this.$t('management.survey.type:satisfaction')
        },
        {
          value: 'subscription',
          text: this.$t('management.survey.type:subscription')
        },
        {
          value: 'external',
          text: this.$t('management.survey.type:external')
        }
      ],

      textToolbarOptions: [
        [
          {
            size: [
              'small',
              false,
              'large'
            ]
          }
        ],
        [
          {
            color: [
              '#fff',
              '#005EB8'
            ]
          }
        ],
        [
          'bold',
          'italic',
          'underline',
          'strike'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [ 'clean' ],
        [ 'link' ]
      ],

      textEditorOptions: {
        formats: [
          'size',
          'color',
          'bold',
          'italic',
          'underline',
          'strike',
          'align',
          'clean',
          'link'
        ],

        modules: {
          clipboard: {
            matchVisual: false
          }
        }
      },

      visibilityOptions: [
        {
          label: this.$t('global:form.management.survey.radio.public'),
          value: true
        },
        {
          label: this.$t('global:form.management.survey.radio.private'),
          value: false
        }
      ],

      formatedUrl: '',
      isSlugDuplicate: false
    }
  },

  validations: {
    formData: {
      name: { required },
      description: { required },
      finishText: { required },
      type: { required },

      visibilityEnabled: {
        required: requiredIf(function () {
          return this.formData.type === 'external'
        })
      },

      slug: {
        required: requiredIf(function () {
          return this.formData.type === 'external'
        }),

        regexSlug: function (event) {
          if (this.formData.type === 'external') {
            const regexSlug = new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$')

            return regexSlug.test(event)
          }

          return true
        },

        slugDuplicate: function () {
          if (this.formData.type === 'external') {
            return this.isSlugDuplicate
          }

          return true
        }
      }
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.id
    },

    showNps () {
      return this.formData.type === 'satisfaction'
    },

    canEditNps () {
      return this.formData.type === 'external'
    },

    descriptionExternal () {
      return this.formData.visibilityEnabled ? this.$t('solution.manage:field.description.show.portal.public') : ''
    }
  },

  watch: {
    'formData.type' (data) {
      this.formData.fixedQuestions = data === 'satisfaction'
    },

    'formData.name' () {
      if (!this.$v.formData.slug.$dirty && !this.isEditing && this.formData.type === 'external') {
        this.formData.slug = formatSlug(this.formData.name)
      }
    },

    'formData.slug' (event) {
      if (!event) return
      this.formData.slug = event.replace(new RegExp('[-]{2,}', 'g'), '-')

      this.debounceEvent(async () => {
        this.setFetching(true)
        const search = { slug: event }

        if (this.isEditing) {
          search.not_id = [ this.formData.id ]
        }

        const surveys = await this.attemptGetSurveyList({ filter: search })
          .then(pagination => { return pagination.data })

        this.isSlugDuplicate = !(surveys.data.length > 0)

        if (!this.isSlugDuplicate) {
          this.$v.formData.slug.$touch()
        }

        this.setFetching(false)
      }, 1000)

      this.formatedUrl = `${process.env.VUE_APP_PORTAL_URL}pesquisa/${this.formData.slug}`
    }
  },

  created () {
    this.setFetching(true)

    if (this.isEditing) {
      this.attemptGetSurvey(this.$route.params.id).then((survey) => {
        if (survey.data.surveyAnswered) {
          this.setFeedback({ message: this.$t('management.survey:feedback.linked.error') })
          this.$router.push({ name: 'management.survey' })
        }

        this.formData = survey.data

        if (survey.data.visibility) {
          this.formData.visibilityEnabled = survey.data.visibility === 'public'
        }
      }).finally(() => {
        this.setFetching(false)
      })
    }

    this.setFetching(false)
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptCreateSurvey',
      'attemptGetSurvey',
      'attemptGetSurveyList',
      'attemptUpdateSurvey'
    ]),

    nextPage () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submit(true)
      }
    },

    leave () {
      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    treatQuestions () {
      this.formData.surveyQuestion = this.formData.surveyQuestion.map((item, index) => {
        if (item.data) {
          item.data.min = parseInt(item.data.min)
          item.data.max = parseInt(item.data.max)
        }

        if (!item.name) item.name = item.title
        item.ordering = index
        item.required = item.required ? 1 : 0

        item.options = item.surveyQuestionOption.map((answer, answerIndex) => {
          answer.ordering = answerIndex
          answer.value = answer.name.replace(/ +/g, '-')

          return answer
        })

        return JSON.stringify(item)
      })
    },

    submitCreation (next) {
      this.setFetching(true)

      return this.attemptCreateSurvey(this.formData).then(({ data }) => {
        this.setFeedback({ message: this.$t('management.survey:feedback.created.success') })

        if (next) {
          this.$router.push({
            name: 'management.survey.edit.questions',
            params: { id: data.id }
          })
        } else {
          this.$router.push(this.backUrl)
        }
      }).catch(({ response }) => {
        this.setFeedback({ message: this.$t(`management.survey:feedback.created.error:${response.data.message.replace(/_/g, '.')}`) })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submitUpdate (next) {
      this.setFetching(true)
      this.treatQuestions()

      return this.attemptUpdateSurvey(this.formData).then(({ data }) => {
        this.setFeedback({ message: this.$t('management.survey:feedback.updated.success') })

        if (next) {
          this.$router.push({
            name: 'management.survey.edit.questions',
            params: { id: data.id }
          })
        } else {
          this.$router.push(this.backUrl)
        }
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.survey:feedback.updated.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submit (next) {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          const submit = this.isEditing ? this.submitUpdate(next) : this.submitCreation(next)

          submit.then(() => {
            resolve(true)
          }).catch(() => {
            reject(new Error('Error'))
          })
        } else {
          this.setFeedback({ message: this.$t('management.survey:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    }
  }
}
</script>

<template>
  <div class="main-content management-survey-create">
    <ContentHeader
      :title="isEditing ? formData.title : $t('management.survey.edit:header.title')"
      dark-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.surveys')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          dark
          flat
          @click="submit(false)"
        />
      </template>
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="management-survey-create-header">
          <h2 class="management-survey-create-title">
            {{ $t('global:form.step', {'num': 1}) }}
          </h2>
          <p
            class="management-survey-create-description"
            v-html="$t('management.survey.edit:header.description.1')"
          />
        </div>

        <form
          class="form"
          @submit.prevent="submit"
        >
          <SelectField
            v-model="formData.type"
            :disabled="isEditing"
            :label="$t('global:form.management.survey.type')"
            :items="surveyTypes"
            :validation="$v.formData.type"
          />
          <InputField
            v-model="formData.name"
            :label="$t('global:form.management.survey.title')"
            :validation="$v.formData.name"
            :counter="100"
          />
          <InputField
            v-if="formData.type === 'external'"
            v-model="formData.slug"
            :label="$t('global:form.management.survey.slug')"
            :under-description="formatedUrl"
            :validation="$v.formData.slug"
            :counter="100"
          />

          <RichTextEditor
            v-model="formData.description"
            :description="$t('global:form.management.survey.description')"
            :toolbar="textToolbarOptions"
            :validation="$v.formData.description"
          />

          <InputField
            v-model="formData.finishText"
            :label="$t('global:form.management.survey.conclusion.text')"
            :validation="$v.formData.finishText"
            :counter="300"
          />
          <Checkbox
            v-if="!isEditing && showNps"
            v-model="formData.fixedQuestions"
            :items="[{label: $t('management.survey.fixed.question.info'), value: true}]"
            :disabled="!canEditNps"
          />
          <div v-if="formData.type === 'external'">
            <FormSection :title="$t('global:form.management.survey.visibility')">
              <Radio
                v-model="formData.visibilityEnabled"
                :under-description="descriptionExternal"
                :items="visibilityOptions"
                horizontal
                :validation="$v.formData.visibilityEnabled"
                :disabled="true"
              />
            </FormSection>
          </div>
        </form>
      </div>
    </div>
    <Pagination
      class="center"
      :active-first-last="false"
      :active-page="pagination.page"
      :page-count="pagination.lastPage"
      @nextPage="nextPage"
    />
  </div>
</template>

<style>
.form-item-description {
  text-transform: none;
}
</style>

<style src="@/assets/styles/themes/default/management.css"></style>
