export const formatSlug = (slug) => {
  let parsedSlug = slug.toLowerCase()

  parsedSlug = parsedSlug.replace(new RegExp('\\s{1,}', 'g'), '-')
  parsedSlug = parsedSlug.replace(/ç/g, 'c')
  parsedSlug = parsedSlug.replace(new RegExp('[àáâãäå]', 'g'), 'a')
  parsedSlug = parsedSlug.replace(new RegExp('[èéêë]', 'g'), 'e')
  parsedSlug = parsedSlug.replace(new RegExp('[ìíîï]', 'g'), 'i')
  parsedSlug = parsedSlug.replace(new RegExp('[òóôõö]', 'g'), 'o')
  parsedSlug = parsedSlug.replace(new RegExp('[ùúûü]', 'g'), 'u')
  parsedSlug = parsedSlug.replace(new RegExp('[^A-Za-z0-9-]', 'g'), '')

  return parsedSlug
}

export const snakeToCamel = (str) => {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase().replace('-', '').replace('_', '')
  )
}

export const removeSpecialCharacters = (str) => {
  let parsedStr = str

  parsedStr = parsedStr.replace(new RegExp('[^A-Za-z0-9-!çáéíóúêôãõ?.,@#$%*:-\\s{}]', 'g'), '')
  parsedStr = parsedStr.replace(/(^|[^{])({)([^{]|$)/g, '$1$3')
  parsedStr = parsedStr.replace(/(^|[^}])(})([^}]|$)/g, '$1$3')

  return parsedStr
}

export const isValidURL = (event) => {
  if (!event) return true
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

  return !!pattern.test(event)
}
